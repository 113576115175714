<template>
  <div></div>
</template>

<script>
export default {
  name: '',
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>
